import ApplicationController from "./application_controller";
import { get } from "@rails/request.js"

export default class extends ApplicationController {
    static targets = ["select"]
    static values = {
        url: String,
        param: String
    }

    connect() {
        if (this.selectTarget.id === "") {
            this.selectTarget.id = Math.random().toString(36)
        }
    }

    change(event) {
        let params = new URLSearchParams()
        console.log(this.selectTarget.id)
        params.append(this.paramValue, event.target.selectedOptions[0].value)
        params.append("target", this.selectTarget.id)

        console.log(`${this.urlValue}?${params}`)
        get(`${this.urlValue}?${params}`, {
            responseKind: "turbo-stream"
        })
    }
}