import ApplicationController from "./application_controller";

// https://www.driftingruby.com/episodes/liquid-templates?query=liquid+stimulus
// Connects to data-controller="email-previewer"
export default class extends ApplicationController {
    static targets = ["input", "output", "applicant", "disponent", "job"];
  connect() {
    //console.log('email previewer controller')
    this.inputTarget.setAttribute("data-action", "keyup->email-previewer#update_output");
    this.update_output();
  }

  update_output() {
    const params = {content: this.inputTarget.value,
          applicant: this.applicantTarget.value,
            disponent: this.disponentTarget.value,
            job: this.jobTarget.value
    }
    const options = {
      method: "POST",
      credentials: "same-origin",
      headers: {
          "Content-Type": "application/json",
        "X-CSRF-Token": this.getMetaValue("csrf-token")
      },
      body: JSON.stringify(params)
    }

    this.currTime = new Date();
    this.elapsed = this.currTime - this.timer;
    if (this.timer == undefined || this.elapsed > 100) {
      fetch("/backend/email_previewers/", options)
          .then(r => r.text())
          .then(text => this.outputTarget.innerHTML = text)
      this.timer = this.currTime;
    } else
      this.outputTarget.innerHTML = this.inputTarget.value;
    }

  getMetaValue(name) {
    const element = document.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
