import algoliasearch from 'algoliasearch/lite'
import instantsearch from 'instantsearch.js'
import { searchBox, hits, pagination, refinementList, numericMenu, sortBy } from 'instantsearch.js/es/widgets';

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "searchbox", "hits", "pagination", "pools", "competencies", "operationareas", "tags", "city", "langde", "langen", "zip", "rating", "sortby", "visa", "persontype", "source" ]

    connect() {

        const data = document.getElementById('instantsearch-init').dataset
        const searchClient = algoliasearch(data.applicationId, data.apiKey);
        const talentFinderSearch = instantsearch({
            indexName: data.index,
            searchClient,
            routing: true
        });


        talentFinderSearch.addWidgets([

            sortBy({
                container: this.sortbyTarget,
                items: [
                        { value: 'Applicant_score', label: 'Punktzahl' },
                        { value: 'Applicant_lastname_asc', label: 'Nachname aufsteigend' },
                        { value: 'Applicant_lastname_desc', label: 'Nachname absteigend' }
                    ],
                defaultRefinement: 'Applicant_score',}),

            searchBox({
                container: this.searchboxTarget,
                cssClasses: {
                    input: 'pl-8',
                },
                style: {
                    'padding-left': '3rem'
                }
            }),

            hits({
                container: this.hitsTarget,
                cssClasses: {
                    item: 'col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 mt-4',
                },
                templates: {
                    empty: '<div class="p-4 text-center text-gray-600">Keine Ergebnisse gefunden</div>',
                    item: `<div class="w-full flex items-center justify-between p-6 space-x-6">
                  <div class="flex-1 truncate">
                    <div class="flex items-center space-x-3">
                      <h3 class="text-gray-900 text-sm font-medium truncate">{{ id }} {{ firstname }} {{ lastname }}</h3>
                      <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{{ person_type }}</span>
                    </div>
                    <div class="flex items-center space-x-3">
                      <p class="mt-1 text-gray-500 text-sm truncate">Titel: {{ title }}</p>
                      <p class="mt-1 text-gray-500 text-sm truncate">Abschluss: {{ academic_title }}</p>
                      <p class="mt-1 text-gray-500 text-sm truncate">Stadt: {{ city }}</p>
                      <p class="mt-1 text-gray-500 text-sm truncate">Level: {{ score }}</p>          
                      <p class="mt-1 text-gray-500 text-sm truncate">Letzte Aktivität: {{ last_activity_date }}</p>                                 
                    </div>
                    <div class="flex flex-row space-x-2 mt-2">
                        {{#pools}}
                      <div class="py-1 px-2 rounded-md text-xs bg-opacity-75 bg-gray-600 font-normal text-white flex justify-center items-center">
                        {{title}}
                      </div>{{/pools}}
                      <div class="py-1 px-2 rounded-md text-xs bg-opacity-75 bg-gray-200 font-normal flex justify-center items-center">
                      Alter: {{ age }}</div>
                    </div>

                    <div class="flex flex-row space-x-2 mt-2">
                      {{#_tags}}
                      <div class="py-1 px-2 rounded-md text-xs bg-opacity-75 bg-purple-600 font-normal text-white flex justify-center items-center">
                        {{ tag }}
                      </div>
                      {{/_tags}}
                    </div>

                    <div class="sm:col-span-2">
                      <dt class="text-sm font-medium text-gray-500">
                        Einsätze
                      </dt>       
                      <dd></dd>   

                  <dt class="text-sm font-medium text-gray-500">
                    Kompetenzen
                  </dt>

                  <dd class="mt-1 text-sm text-gray-900">
                    <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
                      <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div class="w-0 flex-1 flex items-center flex-wrap">
                      {{#competencies}}
                      <div class="py-1 px-2 rounded-md text-xs bg-opacity-75 bg-white-200 font-normal flex justify-center items-center">
                            {{ title }}
                            <span class="bg-gray-100 rounded-lg p-1 ml-1">{{ weight }}</span>
                            
                      </div>{{/competencies}}

                        </div>
                      </li>
                    
                    </ul>
                  </dd>


                  <dt class="text-sm font-medium text-gray-500">
                    Dokumente
                  </dt>

                  <div class="mt-1 p-4 text-sm text-gray-900 border border-gray-200 rounded-md flex-wrap">
                    {{#helpers.highlight}}{ "attribute": "structured_data" }{{/helpers.highlight}}
                  </div>
                <div>
                  <div class="-mt-px flex divide-x divide-gray-200">
                    <div class="w-0 flex-1 flex">
                      <a href="/backend/applicants/{{ id }}" target='_parent' class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                        <!-- Heroicon name: solid/mail -->
                        <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                        </svg>
                        <span class="ml-3">Details</span>
                      </a>
                    </div>
                    <div class="-ml-px w-0 flex-1 flex">
                      <a href="/backend/applicants/{{ id }}" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500" target="_top">
                      <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                      </svg>
                        <span class="ml-3">Teilen</span>
                      </a>
                    </div>
                    <div class="-ml-px w-0 flex-1 flex">
                      <a href="/backend/applicants/profiles/new?applicant_id={{ id }}"  class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500" target="_top">
                     <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
                      </svg>                       
                     <span class="ml-3">Profil</span>
                      </a>
                    </div>                    
                  </div>`

                            
                }
            }),

            pagination({
                container: this.paginationTarget
            }),

            refinementList({
                container: this.persontypeTarget,
                attribute: 'person_type',
                operator: 'or',
                showMore: true,
                cssClasses: {
                    count: ['px-1', 'rounded-md', 'text-sm', 'bg-opacity-50', 'bg-purple-600', 'font-normal', 'text-white'],
                    labelText: ['ml-1', 'font-medium']
                },
                templates: {
                    showMoreText: `
                      {{#isShowingMore}}
                        weniger anzeigen
                      {{/isShowingMore}}
                      {{^isShowingMore}}
                        mehr anzeigen
                      {{/isShowingMore}}
                    `
                }

            }),


            refinementList({
                container: this.poolsTarget,
                attribute: 'pools.title',
                operator: 'or',
                showMore: true,
                cssClasses: {
                    count: ['px-1', 'rounded-md', 'text-sm', 'bg-opacity-50', 'bg-purple-600', 'font-normal', 'text-white'],
                    labelText: ['ml-1', 'font-medium']
                },
                templates: {
                    showMoreText: `
                      {{#isShowingMore}}
                        weniger anzeigen
                      {{/isShowingMore}}
                      {{^isShowingMore}}
                        mehr anzeigen
                      {{/isShowingMore}}
                    `
                }

            }),

            refinementList({
                container: this.visaTarget,
                attribute: 'visa_state',
                operator: 'or',
                showMore: true,
                cssClasses: {
                    count: ['px-1', 'rounded-md', 'text-sm', 'bg-opacity-50', 'bg-purple-600', 'font-normal', 'text-white'],
                    labelText: ['ml-1', 'font-medium']
                },
                templates: {
                    showMoreText: `
                      {{#isShowingMore}}
                        weniger anzeigen
                      {{/isShowingMore}}
                      {{^isShowingMore}}
                        mehr anzeigen
                      {{/isShowingMore}}
                    `
                }

            }),

            refinementList({
                container: this.competenciesTarget,
                attribute: 'competencies.title',
                operator: 'and',
                searchable: true,
                searchablePlaceholder: 'Suchen',
                cssClasses: {
                    count: ['px-1', 'rounded-md', 'text-sm', 'bg-opacity-50', 'bg-purple-600', 'font-normal', 'text-white'],
                    labelText: ['ml-1', 'font-medium']
                },
                templates: {
                    showMoreText: `
                      {{#isShowingMore}}
                        weniger anzeigen
                      {{/isShowingMore}}
                      {{^isShowingMore}}
                        mehr anzeigen
                      {{/isShowingMore}}
                    `
                }
            }),

            refinementList({
                container: this.tagsTarget,
                attribute: '_tags',
                searchable: true,
                operator: 'and',
                showMore: true,
                searchablePlaceholder: 'Suchen',
                cssClasses: {
                    count: ['px-1', 'rounded-md', 'text-sm', 'bg-opacity-50', 'bg-purple-600', 'font-normal', 'text-white'],
                    labelText: ['ml-1', 'font-medium']
                },
                templates: {
                    showMoreText: `
                      {{#isShowingMore}}
                        weniger anzeigen
                      {{/isShowingMore}}
                      {{^isShowingMore}}
                        mehr anzeigen
                      {{/isShowingMore}}
                    `
                }
            }),

            refinementList({
                container: this.operationareasTarget,
                attribute: 'operationareas.title',
                operator: 'and',
                searchable: true,
                searchablePlaceholder: 'Suchen',
                cssClasses: {
                    count: ['px-1', 'rounded-md', 'text-sm', 'bg-opacity-50', 'bg-purple-600', 'font-normal', 'text-white'],
                    labelText: ['ml-1', 'font-medium']
                },
                templates: {
                    showMoreText: `
                      {{#isShowingMore}}
                        weniger anzeigen
                      {{/isShowingMore}}
                      {{^isShowingMore}}
                        mehr anzeigen
                      {{/isShowingMore}}
                    `
                }
            }),

            refinementList({
                container: this.cityTarget,
                attribute: 'city',
                searchable: true,
                operator: 'or',
                showMore: true,
                searchablePlaceholder: 'Suchen',
                cssClasses: {
                    count: ['px-1', 'rounded-md', 'text-sm', 'bg-opacity-50', 'bg-purple-600', 'font-normal', 'text-white'],
                    labelText: ['ml-1', 'font-medium']
                },
                templates: {
                    showMoreText: `
                      {{#isShowingMore}}
                        weniger anzeigen
                      {{/isShowingMore}}
                      {{^isShowingMore}}
                        mehr anzeigen
                      {{/isShowingMore}}
                    `
                }
            }),

            numericMenu({
                container: this.langdeTarget,
                attribute: 'lang_de',
                items: [
                    { label: 'Alle' },
                    { label: 'min. A1⭐', start: 1, end: 1 },
                    { label: 'min. A2⭐', start: 2, end: 6 },
                    { label: 'min. B1⭐', start: 3, end: 6 },
                    { label: 'min. B2⭐', start: 4, end: 6 },
                    { label: 'min. C1⭐', start: 5, end: 6 },
                    { label: 'min. C2⭐', start: 6, end: 6 }],
                cssClasses: {
                    labelText: ['ml-1', 'font-medium']
                }
            }),

            numericMenu({
                container: this.langenTarget,
                attribute: 'lang_en',
                items: [
                    { label: 'Alle' },
                    { label: 'min. A1⭐', start: 1, end: 1 },
                    { label: 'min. A2⭐', start: 2, end: 6 },
                    { label: 'min. B1⭐', start: 3, end: 6 },
                    { label: 'min. B2⭐', start: 4, end: 6 },
                    { label: 'min. C1⭐', start: 5, end: 6 },
                    { label: 'min. C2⭐', start: 6, end: 6 }],
                cssClasses: {
                    labelText: ['ml-1', 'font-medium']
                }
            }),

            numericMenu({
                container: this.ratingTarget,
                attribute: 'average_rating_for_search',
                items: [
                    { label: 'Alle' },
                    { label: 'min. 1⭐', start: 1, end: 5 },
                    { label: 'min. 2⭐', start: 2, end: 5 },
                    { label: 'min. 3⭐', start: 3, end: 5 },
                    { label: 'min. 4⭐', start: 4, end: 5 },
                    { label: 'min. 5⭐', start: 5, end: 5 } ],
                cssClasses: {
                    labelText: ['ml-1', 'font-medium']
                }
            }),

            numericMenu({
                container: this.zipTarget,
                attribute: 'zip',
                items: [
                    { label: 'Alle' },
                    { label: '0..', start: 0, end: 1000 },
                    { label: '1..', start: 1000, end: 1999 },
                    { label: '2..', start: 2000, end: 2999 },
                    { label: '3..', start: 3000, end: 3999 },
                    { label: '4..', start: 4000, end: 4999 },
                    { label: '5..', start: 5000, end: 5999 },
                    { label: '6..', start: 6000, end: 6999 },
                    { label: '7..', start: 7000, end: 7999 },
                    { label: '8..', start: 8000, end: 8999 },
                    { label: '9..', start: 9000, end: 9999 }
                    ],
                cssClasses: {
                    labelText: ['ml-1', 'font-medium']
                }
            }),

            refinementList({
                container: this.sourceTarget,
                attribute: 'source.title',
                searchable: true,
                operator: 'or',
                showMore: true,
                searchablePlaceholder: 'Suchen',
                cssClasses: {
                    count: ['px-1', 'rounded-md', 'text-sm', 'bg-opacity-50', 'bg-purple-600', 'font-normal', 'text-white'],
                    labelText: ['ml-1', 'font-medium']
                },
                templates: {
                    showMoreText: `
                      {{#isShowingMore}}
                        weniger anzeigen
                      {{/isShowingMore}}
                      {{^isShowingMore}}
                        mehr anzeigen
                      {{/isShowingMore}}
                    `
                }
            })


        ]);

        talentFinderSearch.start();

    }



}
