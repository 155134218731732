import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "title", "characterCount", "description", "descriptionButton" ]

    initialize() {
        this.update()
    }

    update() {
        var count = this.titleTarget.value.length
        if (count == 1) {
            var message = "1 Zeichen"
        } else {
            var message = `${count} Zeichen`
        }

        this.characterCountTarget.textContent = message

        if (count > 50) {
            this.characterCountTarget.classList.add("text-danger")
        } else {
            this.characterCountTarget.classList.remove("text-danger")
        }
    }

    submit(event) {
        var count = this.bodyTarget.value.length
        if (count > 50) {
            event.preventDefault()
        }
        this.element.reset()
    }

    // Define a new method called addGenderSpecifier
    addGenderSpecifier() {
        const input = this.titleTarget;
        const value = input.value.trim();


        // Check if the input field value contains (m/w/d) or (m/f/d) or '*in'
        if (!value.includes('(m/w/d)') && !value.includes('(m/f/d)') && !value.includes('*in')) {
            // Add a delay of 3 seconds before adding the gender specifier
            // only type if the inout is not focused and the user is not typing

                setTimeout(() => {
                    input.value = `${value} (m/w/d)`;
                    // flash input once
                    input.classList.remove('animate__animated');
                    input.classList.add('animate__animated');
                    input.classList.add('animate__pulse');
                }, 3000);

        }
    }

    improveTitle() {
        const input = this.titleTarget;
        const title = input.value.trim();

        fetch('/backend/copilot/improve_job_title', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ title: title })
        })
            .then(response => response.json())
            .then(data => {
                this.titleTarget.value = data.improved_title;
                this.titleTarget.classList.add('animate__animated', 'animate__pulse');
            })
            .catch(error => {
                console.error('Error fetching improved title:', error);
            });
    }

    generateDescription() {
        const input = this.titleTarget;
        const title = input.value.trim();
        const button = this.descriptionButtonTarget;

        button.disabled = true;
        button.innerText = "Denke nach..";
        button.classList.remove('animate__animated', 'animate__pulse');
        button.classList.add('animate__animated', 'animate__pulse');
        button.classList.add('animate__infinite','infinite');

        fetch('/backend/copilot/generate_job_description', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ title: title })
        })
            .then(response => response.json())
            .then(data => {
                this.descriptionTarget.editor.setSelectedRange([0, 0]);
                this.descriptionTarget.editor.insertHTML(data.generated_description);
                this.descriptionTarget.classList.remove('animate__animated', 'animate__pulse');
                this.descriptionTarget.classList.add('animate__animated', 'animate__pulse');
                // Re-enable the button and set its text back to the original text
                button.disabled = false;
                button.classList.remove('animate__animated', 'animate__pulse');
                button.classList.remove('animate__infinite','infinite');
                button.innerText = "Fertig 🎉";

            })
            .catch(error => {
                console.error('Error fetching generated description:', error);
                // If there is an error, re-enable the button and set its text back to the original text
                button.disabled = false;
                button.innerText = "Es ist ein Fehler aufgetreten";
            });
    }


}