import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["emailTemplate", "emailSubject", "emailBody", "rewriteButton"]

  changeEmailTemplate(event) {
    const selectedValue = event.target.value;
    fetch(`/backend/email_templates/${selectedValue}.json`)
        .then(response => response.json())
        .then(data => {
          this.emailSubjectTarget.value = data.subject;
          this.emailSubjectTarget.classList.add('animate__animated', 'animate__pulse');
          this.emailBodyTarget.editor.setSelectedRange([0, 0]);
          this.emailBodyTarget.editor.insertHTML(data.body.body);
          this.emailBodyTarget.classList.remove('animate__animated', 'animate__pulse');
          this.emailBodyTarget.classList.add('animate__animated', 'animate__pulse');
        });
  }

    rewrite() {
        const input = this.emailSubjectTarget;
        const subject = input.value.trim();
        const message = this.emailBodyTarget.editor.getDocument().toString();
        const tone = 'friendly';
        const button = this.rewriteButtonTarget;

        button.disabled = true;
        button.innerText = "Denke nach..";
        button.classList.remove('animate__animated', 'animate__pulse');
        button.classList.add('animate__animated', 'animate__pulse');
        button.classList.add('animate__infinite','infinite');

        fetch('/backend/copilot/rewrite_message', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                subject: subject,
                message: message,
                tone: tone })
        })
            .then(response => response.json())
            .then(data => {
                this.emailBodyTarget.editor.setSelectedRange([0, 0]);
                this.emailBodyTarget.editor.insertHTML(data.rewritten_message);
                this.emailBodyTarget.classList.remove('animate__animated', 'animate__pulse');
                this.emailBodyTarget.classList.add('animate__animated', 'animate__pulse');
                // Re-enable the button and set its text back to the original text
                button.disabled = false;
                button.classList.remove('animate__animated', 'animate__pulse');
                button.classList.remove('animate__infinite','infinite');
                button.innerText = "Fertig 🎉";

            })
            .catch(error => {
                console.error('Error fetching generated description:', error);
                // If there is an error, re-enable the button and set its text back to the original text
                button.disabled = false;
                button.innerText = "Es ist ein Fehler aufgetreten";
            });
    }
}
